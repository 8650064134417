import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import Popover from "../components/Popover";
import StartiappLogo from "../components/StartiappLogo";
import useLocalStorage from "../hooks/useLocalStorage";
import NotInStartiappWarning from "./NotInStartiappWarning";
import styles from "./Root.module.css";

export interface OutletContext {
  url: string;
  setUrl: (url: string) => void;

  setPopoverContent: (content: JSX.Element) => void;
  setPopoverOpen: (open: boolean) => void;
}

export default function Root() {
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [url, setUrl] = useLocalStorage("startUrl", "https://holion.dk");
  const [popoverContent, setPopoverContent] = useState(<></>);

  function openInfoPopover() {
    setPopoverContent(
      <>
        <p>{t("AboutParagraph1")}</p>
        <br />
        <p>{t("AboutParagraph2")}</p>
      </>
    );
    setPopoverOpen(true);
  }

  return (
    <div className={styles.container}>
      <NotInStartiappWarning />

      <Popover open={popoverOpen} onClose={() => setPopoverOpen(false)}>
        {popoverContent}
      </Popover>

      <div
        className={styles.header}
        onClick={() => window.location.reload()}
        onKeyDown={() => window.location.reload()}
      >
        <StartiappLogo />
      </div>

      <div className={styles.content}>
        <Outlet
          context={{
            url,
            setUrl,
            setPopoverContent,
            setPopoverOpen,
          }}
        />
      </div>

      <div className={styles.footer}>
        <button className={styles.infoButton} onClick={openInfoPopover}>
          {t("AboutButton")}
        </button>
      </div>
    </div>
  );
}
