import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";
import GoBack from "../../components/GoBack";
import Spinner from "../../components/Spinner";
import { OutletContext } from "../Root";
import styles from "./CreateQr.module.css";
import {
  isValidEmail,
  isValidUrl,
  rfc5322EmailRegex,
  urlRegex,
} from "./validators";

export default function CreateQrComponent() {
  const { t } = useTranslation();
  const { setPopoverContent, setPopoverOpen } =
    useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const qrGeneratorEndpoint =
    "https://systemstartiapp.azurewebsites.net/api/QrGenerator";
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [generatingQr, setGeneratingQr] = useState(false);
  const [generatingError, setGeneratingError] = useState<null | string>(null);

  const canGenerateQr = isValidEmail(email) && isValidUrl(url) && !generatingQr;

  function openQrGeneratedPopover() {
    setPopoverContent(
      <>
        <p>{t("AboutParagraph1")}</p>
        <br />
        <p>{t("AboutParagraph2")}</p>
      </>
    );
    setPopoverOpen(true);
  }

  async function generateQrCode(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    event.currentTarget.focus();

    if (!canGenerateQr) return;

    const requestParams = new URLSearchParams({
      code: "KMPX6afQFwtiguOWLmZDyAQmH7MxvHFY2ISs8D1k0MUDAzFuZ8osDg==",
      email,
      url,
    });

    setGeneratingQr(true);
    try {
      setGeneratingError(null);
      const requestUrl = `${qrGeneratorEndpoint}?${requestParams.toString()}`;
      const response = await fetch(requestUrl);

      if (!response.ok) {
        setGeneratingError(response.statusText);
        return;
      }

      openQrGeneratedPopover();
      navigate("/");
    } catch (error) {
      setGeneratingError("An error occurred while generating the QR code");
    } finally {
      setGeneratingQr(false);
    }
  }

  return (
    <>
      <GoBack />
      <form className={styles.inputContainer} onSubmit={generateQrCode}>
        <div className={styles.inputWrapper}>
          <input
            type="url"
            autoCapitalize="off"
            onChange={(e) => setUrl(e.target.value)}
            placeholder="URL"
            className={isValidUrl() ? "" : styles.inputError}
            required
            pattern={urlRegex.source}
          />
          <img
            src={
              isValidUrl()
                ? require("../../images/checkmark_icon.png")
                : require("../../images/url_icon.png")
            }
            alt=""
          />
        </div>
        <div className={styles.inputWrapper}>
          <input
            type="email"
            autoCapitalize="off"
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className={isValidEmail() ? "" : styles.inputError}
            required
            pattern={rfc5322EmailRegex.source}
          />
          <img
            src={
              isValidEmail()
                ? require("../../images/checkmark_icon.png")
                : require("../../images/email_icon.png")
            }
            alt=""
          />
        </div>
        <button disabled={!canGenerateQr} type="submit">
          {generatingQr ? (
            <Spinner style={{ scale: "0.6" }} />
          ) : (
            t("SharedPerform")
          )}
        </button>
        {generatingError && (
          <p className={styles.errorText}>{generatingError}</p>
        )}
      </form>
    </>
  );
}
