import styles from "./StartiappLogo.module.css";

export default function StartiappLogo() {
  return (
    <svg viewBox="0 0 350 600" className={styles.logo}>
      <path d="M325 387V37c0-7-6-12-12-12H37c-7 0-12 5-12 12v450h325v76c0 20-17 36-37 36H37c-20 0-37-16-37-36v-1h25v1c0 6 5 11 12 11h276c6 0 12-5 12-11v-51H0V37C0 17 17 0 37 0h276c20 0 37 17 37 37v350h-25Z" />
      <rect width="82.7" height="82.7" x="181" y="305" rx="24.3" ry="24.3" />
      <rect width="82.7" height="82.7" x="86" y="305" rx="24.3" ry="24.3" />
      <path d="M239 222c7 0 12 5 12 12v34c0 7-5 13-12 13h-34c-7 0-12-6-12-13v-34c0-7 5-12 12-12h34m0-12h-34c-13 0-24 11-24 24v34c0 14 11 25 24 25h34c14 0 24-11 24-25v-34c0-13-10-24-24-24Z" />
      <rect width="82.7" height="82.7" x="86" y="210" rx="24.3" ry="24.3" />
      <circle cx="174.7" cy="62.1" r="12.6" />
    </svg>
  );
}
