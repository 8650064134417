import { useNavigate } from 'react-router-dom';
import styles from './GoBack.module.css';

export default function GoBack() {
    const navigate = useNavigate();
    return (
        <div className={styles.return} onClick={() => navigate("/")}>
            <div />
            <div />
            <div />
        </div>
    );
}
