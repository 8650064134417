import styles from './Spinner.module.css';


interface SpinnerProps {
    style?: React.CSSProperties;
    color?: string;
}
const Spinner = ({ style, color }: SpinnerProps) => (
    <div style={style} className={`${styles.lds_ellipsis} ${styles.scale}`}>
        <div style={{ background: color }} />
        <div style={{ background: color }} />
        <div style={{ background: color }} />
        <div style={{ background: color }} />
    </div>
);

export default Spinner;