import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import startiapp from "starti.app";
import Spinner from "../../components/Spinner";
import styles from './Actions.module.css';

export default function MainActionsComponent() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    async function scanQrCode() {
        try {
            const scanResult = await startiapp.QrScanner.scan();
            // Simulate scan result after 2 seconds
            // const scanResult = await new Promise<string>((resolve) => setTimeout(() => resolve("https://www.google.com"), 2000));
            const urlRegex = new RegExp(/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/);
            if (urlRegex.test(scanResult)) {
                setLoading(true);
                startiapp.App.setAppUrl(scanResult);
                window.location.href = scanResult;
            }
        } catch (error) {
            console.error(error);
        }
    }

    return loading ? <Spinner style={{ scale: "1" }} /> : <>
        <div className={styles.switchStyle}>
            <button onClick={() => navigate("/enter-url")}>
                <img src={require("../../images/url_icon.png")} alt="Enter url" />
                {t("MenuEnterUrl")}
            </button>
            <hr />
            <button onClick={() => scanQrCode()}>
                <img src={require("../../images/qr_icon.png")} alt="Scan qr code" />
                {t("MenuScanQr")}
            </button>
            <hr />
            <button onClick={() => navigate("/create-qr")}>
                <img src={require("../../images/qr_generate_alt_icon.png")} alt="Create qr code" />
                {t("MenuCreateQr")}
            </button>
        </div>
    </>;
}
