export const commonScript = `
(async () => {
	const baseUrl = "https://system.starti.app/";
	if (new URL(baseUrl).host == location.host)
		return;

	function createReturnButton() {
		const returnButton = document.createElement("div");
		returnButton.id = "startiapp-return-button";
		returnButton.style.position = "fixed";
		returnButton.style.top = "calc(8px + var(--startiapp-inset-top, 0px))";
		returnButton.style.left = "calc(8px + var(--startiapp-inset-left, 0px))";
		returnButton.style.zIndex = "2147483647";
		returnButton.style.backgroundColor = "#f7931d";
		returnButton.style.paddingInline = "10px";
		returnButton.style.borderRadius = "6px";

		const returnButtonText = document.createElement("div");
		returnButtonText.innerHTML = "← starti.app";
		returnButtonText.style.color = "white";
		returnButtonText.style.fontWeight = "bold";
		returnButtonText.style.fontSize = "20px";
		returnButtonText.style.padding = "8px 0px";
		returnButtonText.style.cursor = "pointer";
		returnButtonText.style.userSelect = "none";
		returnButtonText.style.lineHeight = "1";

		returnButton.addEventListener("click", () => {
			try {
				AppIntegration.setAppUrl(baseUrl)
			} catch (error) {}

			window.location.href = baseUrl;
		});

		returnButton.appendChild(returnButtonText);
		document.body.appendChild(returnButton);

		return returnButton;
	}

	const queryParams = new URLSearchParams(window.location.search);
	const manuallyInitialized = queryParams.get("manuallyInitialized") === "true";

	if (!manuallyInitialized)
        AppIntegration.webAppIsReady();

	// Create return button
	const returnButton = createReturnButton();
	setTimeout(() => {
		returnButton.remove();
	}, 5000);
})();
`;
