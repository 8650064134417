import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { createHashRouter, RouterProvider } from "react-router-dom";
import startiapp from "starti.app";
import { commonScript } from "./commonScript";
import "./i18n";
import "./index.css";
import MainActionsComponent from "./routes/Actions/Actions.component";
import CreateQrComponent from "./routes/CreateQrCode";
import EnterUrl from "./routes/EnterUrl";
import Root from "./routes/Root";

const router = createHashRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { index: true, element: <MainActionsComponent /> },
      {
        path: "create-qr",
        element: <CreateQrComponent />,
      },
      {
        path: "enter-url",
        element: <EnterUrl />,
      },
    ],
  },
]);

startiapp.addEventListener("ready", function () {
  startiapp.App.setCommonScript(commonScript);

  startiapp.initialize({
    statusBar: {
      removeSafeArea: true,
      hideText: false,
      darkContent: false,
      safeAreaBackgroundColor: "#f7931d",
    },
    allowRotation: true,
    spinner: { show: false },
  });
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
