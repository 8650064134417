import { ChangeEvent, FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import startiapp from "starti.app";
import GoBack from "../../components/GoBack";
import { OutletContext } from "../Root";
import styles from "./EnterUrl.module.css";

export default function EnterUrlComponent() {
  const { url, setUrl } = useOutletContext<OutletContext>();
  const { t } = useTranslation();

  function handleUrlChangeEvent(event: ChangeEvent<HTMLInputElement>) {
    setUrl(event.target.value);
  }

  function goToUrl(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      startiapp.App.setAppUrl(url);
    } catch (error) {
      console.error(error);
    }
    window.location.href = url;
  }

  return (
    <>
      <GoBack />
      <form className={styles.inputContainer} onSubmit={goToUrl}>
        <div className={styles.inputWrapper}>
          <input
            type="url"
            autoCapitalize="off"
            placeholder="URL"
            spellCheck="false"
            value={url}
            onChange={handleUrlChangeEvent}
          />
          <img
            src={require("../../images/url_icon.png")}
            alt=""
            className="urlIcon"
          />
        </div>
        <button className="complete" type="submit">
          {t("SharedPerform")}
        </button>
      </form>
    </>
  );
}
