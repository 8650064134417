import { t } from "i18next";
import startiapp from "starti.app";
import styles from "./NotInStartiappWarning.module.css";

export default function NotInStartiappWarning() {
  return (
    <>
      {!startiapp.isRunningInApp() && (
        <strong className={styles.warning}>{t("NotStartedInApp")}</strong>
      )}
    </>
  );
}
